<template>
  <section class="popular-item-container">
    <v-container>
      <v-row>
        <v-col style="position: relative; width: 100%;">
          <div class="h2-container">
            <div class="h2-wap">
              <h2>熱門分類</h2>
            </div>
            <div class="hr" />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="productLists" class="popular-item-container-v2">
      <v-row>
        <v-col
          v-for="item in filteredProductLists"
          :key="item.id"
          cols="12"
          sm="3"
          class="li-list"
        >
          <router-link
            :to="`/store.linerp.co/product-lists/${providerId}/${item.id}`"
          >
            <div style="position: relative; width: 100%;">
              <v-img
                v-if="item.productItem[0].photos[0]"
                class="v-image-img"
                width="100%"
                aspect-ratio="1"
                cover
                :src="item.productItem[0].photos[0].size_list.origin.url"
              >
              </v-img>
              <v-img
                v-else
                class="v-image-img"
                src="~@/assets/product-dafault.jpeg"
                width="100%"
                aspect-ratio="1"
                cover
              ></v-img>
              <div class="overlayer">
                <h3>{{ item.name }}</h3>
              </div>
            </div>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "PopularItems",
  components: {},
  props: {
    providerId: {
      type: String,
    },
    productLists: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    filteredProductLists() {
      return this.productLists
        .filter((item) => item.productItem != undefined)
        .slice(0, 4);
    },
  },
  // created() {
  //   console.log("cc1", this.productLists);
  // },
  // mounted() {
  //   console.log("cc2", this.productLists);
  // },
  methods: {},
};
</script>

<style>
.popular-item-container {
  margin-bottom: 20px;
}

.popular-item-container-v2 {
  /* background-color: rgb(199, 199, 199, 0.2); */
  background-color: rgb(0 71 55 / 80%);
  border-radius: 10px;
  overflow: hidden;
  padding: 20px 30px;
}
.overlayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  color: white;
  transition: all 0.2s ease-out;
}
.overlayer:hover {
  background-color: rgba(0, 0, 0, 0);
}
.li-list {
  transition: transform 0.3s ease-in-out;
}

.li-list:hover {
  transform: scale(1.1);
}
.h2-container {
  margin-bottom: 30px;
}
h2 {
  font-size: 30px;
  font-weight: 600;
}
.h2-wap {
  background-color: white;
  width: 323px;
  text-align: center;
  z-index: 1;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 1024px) {
  .h2-wap {
    width: 140px;
  }
  h2 {
    font-size: 24px;
  }
}
.hr {
  position: absolute;
  top: 15px;
  right: 0px;
  width: 100%;
  z-index: 0;
  background-color: rgb(0 71 55 / 80%);
  height: 1px;
}
.overlayer h3 {
  font-size: 28px;
  font-weight: 600;
}
.v-image-img {
  aspect-ratio: 16 / 9;
  border-radius: 10px;
  overflow: hidden;
}
</style>
